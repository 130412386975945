import useGTM from "@elgorditosalsero/react-gtm-hook"
import { Box, Container, Typography } from "@mui/material"
import { usePlanCodes } from "@talentinc/gatsby-theme-ecom/components/NewCheckout/usePlanCodes"
import { tagPurchaseGTMEvent } from "@talentinc/gatsby-theme-ecom/components/NewUpsell/TagPurchaseEvent"
import { usePurchasePageEvent } from "@talentinc/gatsby-theme-ecom/hooks/useCheckoutEvent"
import useDiscountToken from "@talentinc/gatsby-theme-ecom/hooks/useDiscountToken"
import React, { useEffect, useRef } from "react"
import useLocation from "react-use/lib/useLocation"
import * as logger from "../../utils/logging"

import { CircularProgress } from "@mui/material"
import { navigate } from "gatsby"
import useSearchParam from "react-use/lib/useSearchParam"
import { SPINNER_SIZE } from "../../types/widget"
import { getOnePurchase, Purchase, triggerVWOTracking } from "./api"

const MAX_POLL_ATTEMPTS   = 60
const POLL_INTERVAL_IN_MS = 1000

export const ProcessingPaymentPage = () => {
  const planCodes = usePlanCodes()
  const urlDiscountToken = useDiscountToken()

  const purchaseId = useSearchParam("purchaseId")
  const plan = useSearchParam("plan")
  const name = useSearchParam("name")
  const redirectUrl = encodeURIComponent(useSearchParam("redirectUrl") || "/")
  const { sendDataToGTM } = useGTM()
  const { pathname, hostname } = useLocation()

  const pollAttemptsLeft = useRef<number>(MAX_POLL_ATTEMPTS)

  usePurchasePageEvent({
    planCodes: planCodes,
    discountToken: urlDiscountToken,
  })

  const triggerGTMEvent = (payload: Purchase, successUrl: string) => {
    try {
      const { gtmData, isNewTransaction } = tagPurchaseGTMEvent(payload, { pathname, hostname })
      isNewTransaction && sendDataToGTM({ ...gtmData, eventCallback: () => window.location.href = successUrl })
    } catch (e) {
      logger.error(e)
      return null
    }
  }

  const pollData = async (timer: ReturnType<typeof setInterval>, purchaseId: string, plan: string) => {
    if (pollAttemptsLeft.current < 1) {
      navigate(`/purchase/failed?plan=${plan}&name=${name}`)
      return
    }

    try {
      const response       = await getOnePurchase(purchaseId)
      const purchase       = response.data
      const purchaseStatus = purchase.status.toLowerCase()

      if (purchaseStatus === "pending" || purchaseStatus === "received") {
        pollAttemptsLeft.current = pollAttemptsLeft.current - 1
      } else if (purchaseStatus === "authorised") {
        const orderId    = (purchase?.orderIds || [""])[0]
        const successUrl = `/purchase/successful?redirectUrl=${redirectUrl}`.replace("order%3Dundefined", `order%3D${orderId}`)

        clearInterval(timer)
        triggerGTMEvent(purchase, successUrl)
        triggerVWOTracking(true, purchase.totalUSD, plan)

        if (typeof window !== "undefined" && !window.google_tag_manager) {
          setTimeout(() => {
            navigate(successUrl)
          }, 800)
        }
      } else {
        pollAttemptsLeft.current = 0
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!purchaseId || !plan) return

    const timer = setInterval(() => {
      pollData(timer, purchaseId, plan)
    }, POLL_INTERVAL_IN_MS)

    return () => {
      clearInterval(timer)
    }
  }, [purchaseId, plan])

  return (
    <Container
      sx={{
        margin: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "12px",
        paddingBottom: "32px",
      }}
    >
      <Box
        p={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          background: "white",
          borderRadius: "8px",
        }}
      >
        <CircularProgress
          color="primary"
          size={SPINNER_SIZE}
          style={{ marginBottom: "8px" }}
        />

        <Typography variant="h2">Please wait...</Typography>

        <Typography variant="body1">
          Please hold on as we're processing your payment. It should take a few
          seconds
        </Typography>
      </Box>
    </Container>
  )
}
