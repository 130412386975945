import Cookies from "js-cookie"
import { Purchase } from "@talentinc/gatsby-theme-ecom/components/NewCheckout/api"

const COOKIE_KEY = "gtm_tracked_transactions"

export const tagPurchaseGTMEvent = (
  purchase: Purchase,
  location: { pathname?: string; hostname?: string },
  event = "Purchase"
) => {
  const cookieTrackedTransactions = JSON.parse(Cookies.get(COOKIE_KEY) || '[]')
  const trackedTransactions = new Set(cookieTrackedTransactions)
  const isNewTransaction = !trackedTransactions.has(purchase.transactionId)

  if (isNewTransaction) {
    trackedTransactions.add(purchase.transactionId)
    Cookies.set(COOKIE_KEY, JSON.stringify(Array.from(trackedTransactions)), {
      expires: 365,
      domain: location?.hostname,
      path: location?.pathname,
    })
  }

  const gtmData = {
    transactionId: purchase.transactionId,
    transactionTotal: purchase.totalUSD,
    transactionTotalInCents: purchase.totalUSD * 100,
    transactionTotalInCurrency: purchase.totalUSD,
    transactionTotalInCurrencyCents: purchase.totalUSD * 100,
    transactionCurrency: purchase.currency,
    hashedEmail: purchase.lead.hashedEmail,
    userLeadAge: purchase.lead.age,
    userLeadScore: purchase.lead.score,
    transactionAffiliation: purchase.brandName,
    transactionProducts: purchase.products.planPrices.map((plan) => ({
      sku: plan.planCode,
      name: plan.planName,
      category: 'N/A',
      price: plan.price,
      quantity: 1,
    })),
    event,
    ...(purchase.partnerId && { partnerId: purchase.partnerId }),
  }

  return {
    gtmData,
    isNewTransaction,
  }
}
